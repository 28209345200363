import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import StuporBowlResults from './StuporBowlResults';

ReactDOM.render(
    <React.StrictMode>
        <StuporBowlResults />
    </React.StrictMode>,
    document.getElementById('root')
);

module.hot.accept();
