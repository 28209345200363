import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import './StuporBowlResults.css';
import NEXT_PUBLIC_SERVICE_URL from './constants';

export default function StuporBowlResults () {
    const [questions, setQuestions] = useState(undefined);
    const [answers, setAnswers] = useState(undefined);

    const headers = {
        'x-api-key':
    '0KhaRO9nK5AdnSJCeuMpcmgWTV24W74DiLQZuvxT0PCvSQ2tmyex0DorJGsGURLq',
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        if (questions === undefined) {
            axios
                .get(`${NEXT_PUBLIC_SERVICE_URL}/results`, {
                    headers
                })
                .then((response) => {
                    if (response.data && response.data.questions && response.data.answers) {
                        setQuestions(response.data.questions);
                        setAnswers(response.data.answers);
                    } else {
                        setQuestions([]);
                        setAnswers([]);
                        toast.error("Could not retrieve questions");
                    }
                });
        }
    }, [questions, answers]);

    document.title = 'Stupor Bowl LIX (2025) results';
    let winners = [];
    let winnerRows = [];
    let questionRows = [];
    if (questions && questions.length > 0 && answers && answers.length > 0) {
        answers.forEach(answer => {
            let name = `${answer.firstName} ${answer.lastName}`;
            let score = 0;
            const responses = answer.answers.split(',');
            for (var i = 0; i < responses.length; i++) {
                if (responses[i].toString().toLowerCase() === questions[i].finalAnswer.toString().toLowerCase()) {
                    score += parseInt(questions[i].points, 10);
                }
            }
            winners.push({ name, score });
        });
        let j = 1;
        questions.forEach((question, i) => {
            questionRows.push(<tr key={`question_${i}`}>
                <td>{j}. {question.question}</td>
                <td>{question.finalAnswer}</td>
                <td>{question.notes}</td>
            </tr>);
            j++;
        });
        if (winners.length > 0) {
            winners.sort((a, b) =>
                // eslint-disable-next-line no-nested-ternary
                a.score < b.score ? 1 : b.score < a.score ? -1 : 0,
            );
            winners.forEach((winner, i) => {
                winnerRows.push(<tr key={`winner_${i}`}><td>{winner.name}:</td><td>{winner.score}</td></tr>);
            });
        }
    }
    return (
        <div className="stupor">
            <h1 className="entry-title">Stupor Bowl LIX (2025) Rankings</h1>

            <table className="stupor-table">
                <thead>
                    <tr><th>Name</th><th>Score</th></tr>
                </thead>
                <tbody>
                    {winnerRows}
                </tbody>
            </table>
            <h3>Final Answers</h3>
            <table className="answer-table">
                <thead>
                    <tr><th>Question</th><th>Final Answer</th><th>Notes</th></tr>
                </thead>
                <tbody>
                    {questionRows}
                </tbody>
            </table>
            <Toaster />
        </div>
    );
}
